import React, { useState, useEffect } from 'react';
import '../styles/PrivateSaleDGKO.css';
import { web, TransactionType } from '@klever/sdk-web';

function PreSaleDGKO() {
    const [balance, setBalance] = useState('0');
    const [ktcBalance, setKtcBalance] = useState('0');
    const [amount, setAmount] = useState('');
    const [tokensToReceive, setTokensToReceive] = useState('0');
    const [walletAddress, setWalletAddress] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const pricePerToken = 2.75;
    const [isLoading, setIsLoading] = useState(false);
    const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
    const isValidAmount = parseFloat(amount) >= 100 && parseFloat(amount) <= 150000;
    const numericKtcBalance = parseFloat(ktcBalance);
    const numericTokensToReceive = parseFloat(tokensToReceive);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [transactionResultMessage, setTransactionResultMessage] = useState('');
    const [countdownTime, setCountdownTime] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
  });

    const [isCountdownFinished, setIsCountdownFinished] = useState(false);



    useEffect(() => {
      // Save the current body style to revert back later
      const originalStyle = document.body.style.cssText;
  
      // Create a new overlay div and apply styles to it
      const overlayDiv = document.createElement("div");
      overlayDiv.style.position = 'fixed';
      overlayDiv.style.left = 0;
      overlayDiv.style.top = 0;
      overlayDiv.style.width = '50%';
      overlayDiv.style.height = '50%';
     
      // Adjust the background position here to move the image more to the left
      overlayDiv.style.backgroundPosition = '10% center'; // Example: starts 10% from the left
      overlayDiv.style.backgroundRepeat = 'no-repeat';
      overlayDiv.style.backgroundSize = 'contain';
      overlayDiv.style.opacity = '0.66';
      overlayDiv.style.zIndex = '-1'; // Ensure it's behind everything else
      overlayDiv.style.pointerEvents = 'none'; // Ensure clicks pass through
  
      // Apply the primary background style to the body element
      document.body.style.backgroundColor = '#000000';

      document.body.style.backgroundPosition = 'bottom center';
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundSize = 'cover';
  
      // Append the overlay to the body
      document.body.appendChild(overlayDiv);
  
      // Function to revert back to the original body style and remove the overlay when the component unmounts
      return () => {
          document.body.style.cssText = originalStyle;
          document.body.removeChild(overlayDiv);
      };
  }, []);
    

    const fetchKtcBalance = async () => {
        const address = 'klv1qasgvna4w7zc5g3aj5l9djh9l6v3g7kmsdjj722k6993dqgzxjasur3nd3';
        const url = `https://api.mainnet.klever.finance/v1.0/address/${address}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            const ktcData = data.data.account.assets['DGKO-CXVJ'];
            if (ktcData) {
                const ktcBalance = (ktcData.balance / Math.pow(10, 4)).toFixed(0);
                setKtcBalance(ktcBalance);
            }
        } catch (error) {
            console.error('Error fetching DGKO-CXVJ balance:', error);
        }
    };

    // Asynchronously fetch KLV balance
    const fetchKlvBalance = async () => {
        if (!walletAddress) return; // Only proceed if walletAddress is set
        const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            const klvData = data.data.account.assets['KLV'];
            if (klvData) {
                const klvBalance = adjustBalance(klvData.balance, klvData.precision);
                setBalance(klvBalance);
            }
        } catch (error) {
            console.error('Error fetching KLV balance:', error);
        }
    };

    // useEffect hook for fetching and refreshing balances
    useEffect(() => {
        fetchKtcBalance(); // Fetch KTC balance immediately
        if (walletAddress) {
            fetchKlvBalance(); // Fetch KLV balance immediately if walletAddress is set
        }

        const interval = setInterval(() => {
            fetchKtcBalance(); // Refresh KTC balance
            if (walletAddress) {
                fetchKlvBalance(); // Refresh KLV balance if walletAddress is set
            }
        }, 2000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [walletAddress]);
  
    const adjustBalance = (balance, precision) => {
      const factor = Math.pow(10, precision || 4); // Default to 4 decimal places if precision is not provided
      return (balance / factor).toFixed(precision || 4); // Convert to a string with fixed decimal places
    };
    

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 6)}`;
  };

  const handlePercentageClick = (percentage) => {
    let calculatedAmount = 0;
    // Calculate the percentage of the balance
    if (percentage === '100') {
        // Subtract 5 KLV for the 100% case
        calculatedAmount = ((balance * percentage) / 100) - 5;
    } else {
        calculatedAmount = (balance * percentage) / 100;
    }
    // Ensure calculatedAmount does not go negative
    calculatedAmount = Math.max(calculatedAmount, 0);
    // Limit to a maximum of 6 decimal places and parse back to float
    calculatedAmount = parseFloat(calculatedAmount.toFixed(6));
    setAmount(calculatedAmount.toString());
    calculateTokensToReceive(calculatedAmount.toString());
    setShowInstallButton(false);
};

  
  

const calculateTokensToReceive = (inputAmount) => {
  // Convert the input amount to a number, default to 0 if NaN
  const amount = parseFloat(inputAmount) || 0;
  const tokens = amount / pricePerToken; // Calculate the tokens to receive based on the input amount
  
  // Set tokens to receive, ensuring it's never NaN. If calculation results in NaN, default to 0.
  setTokensToReceive(isNaN(tokens) ? '0' : tokens.toFixed(4)); // Assuming 4 decimal places for tokens
};


const handleAmountChange = (e) => {
  const inputAmountStr = e.target.value; // Directly use the string value for checks
  let inputAmount = parseFloat(inputAmountStr);
  let transactionFee = 5; // Define the transaction fee
  let actualBalance = parseFloat(balance); // Parse the actual balance to a float

  // If the input is not a number (empty or invalid), reset amount and tokens to receive to 0
  if (isNaN(inputAmount)) {
      setAmount('');
      calculateTokensToReceive('0');
      setShowInstallButton(false);
      return;
  }

  // Adjust the input amount to account for the transaction fee if necessary
  if (inputAmount + transactionFee >= actualBalance) {
      inputAmount = Math.max(actualBalance - transactionFee, 0);
  }

  setAmount(inputAmount.toString());
  calculateTokensToReceive(inputAmount.toString());
  setShowInstallButton(false);
};




  
  
  const enforceConstraints = () => {
    let newAmount = parseFloat(amount);
    newAmount = isNaN(newAmount) ? '' : newAmount.toString(); // Handle non-numeric input without enforcing min/max
    setAmount(newAmount);
    calculateTokensToReceive(newAmount);
  };


  const connectWallet = async () => {
    try {
      // Check for Klever extension
      if (typeof window === "undefined" || !window.kleverWeb) {
        throw new Error("Klever Extension not found");
      }
      // Proceed with wallet connection
      await web.initialize();
      const address = await web.getWalletAddress();
      setWalletAddress(address);
      setIsConnected(true);
      console.log('Wallet connected:', address);
      // Reset states on successful connection
      setTransactionStatusMessage('');
      setShowInstallButton(false);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      if (error.message === "Klever Extension not found") {
        // Update message and show the install button
        setTransactionStatusMessage('Klever Extension not found. Please install it to proceed.');
        setShowInstallButton(true);
      } else {
        // Handle other errors
        setTransactionStatusMessage('Error connecting wallet. Please try again.');
        setShowInstallButton(false);
      }
    }
  };

  useEffect(() => {
    let message = '';
    if (transactionResultMessage) {
        // If there's a transaction result message, don't update the general status message
        return;
    } else if (isLoading) {
        message = 'Processing...';
    } else if (!isConnected) {
        message = 'Please connect your wallet.';
    } else if (amount === '') {
        message = 'Type in amount of KLV.';
    } else if (!isValidAmount) {
        message = 'PER PURCHASE\nMinimum amount: 100 KLV\nMaximum amount: 150000 KLV';
    } else if (numericTokensToReceive > numericKtcBalance) {
        message = 'Not enough DGKO for sale.';
    } else if (parseFloat(amount) > parseFloat(balance)) {
        message = 'Insufficient KLV balance.';
    } else {
        message = 'Ready to buy';
    }
    setTransactionStatusMessage(message);
}, [isLoading, isConnected, amount, isValidAmount, numericTokensToReceive, numericKtcBalance, balance, transactionResultMessage]); // Add transactionResultMessage as a dependency

useEffect(() => {
  const targetDate = new Date("2024-10-01T15:00:00Z").getTime();

  const interval = setInterval(() => {
    const now = new Date().getTime();
    const distance = targetDate - now;

    if (distance < 0) {
      clearInterval(interval);
      setIsCountdownFinished(true); // Set countdown finished to true when countdown ends
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountdownTime({ days, hours, minutes, seconds });
    }
  }, 1000);

  return () => clearInterval(interval);
}, []);



const sendTransaction = async () => {
  setIsLoading(true);
  // Clear any previous general status message; not necessary if using separate result message
  // setTransactionStatusMessage('');

  const actualAmountToSend = Math.round(parseFloat(amount) * Math.pow(10, 6));

  const payload = {
      amount: actualAmountToSend,
      receiver: 'klv1qasgvna4w7zc5g3aj5l9djh9l6v3g7kmsdjj722k6993dqgzxjasur3nd3',
      kda: 'KLV',
  };

  try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }]);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);

      console.log(response);
      // Use setTransactionResultMessage for transaction-specific messages
      setTransactionResultMessage('Transaction successful.\nPlease wait for balance to update.');
      setTimeout(() => {
          // Clear the transaction result message after an appropriate amount of time
          setTransactionResultMessage('');
      }, 7000);
  } catch (error) {
      console.error('Transaction error:', error);
      // Use setTransactionResultMessage for transaction-specific error messages
      setTransactionResultMessage('Transaction failed.\nPlease try again.');
      setTimeout(() => {
          // Clear the transaction result message after an appropriate amount of time
          setTransactionResultMessage('');
      }, 7000);
  } finally {
      setIsLoading(false);
      // Reset amount and tokensToReceive regardless of transaction outcome
      setAmount('');
      setTokensToReceive('0');
  }
};

  

return (
  <div className="pre-sale-page">
      <a href="https://digiko.io/" target="_blank" rel="noopener noreferrer">
                <img
                    src="https://framerusercontent.com/images/9ixK858cSIaS2UAxcGvESTs7b1M.png?w=2048"
                    alt="Logo"
                    className="pre-sale-logo"
                />
            </a>
            <div className="pre-sale-header">
    <h1>PRE-SALE</h1>
    <h2><span className="moto">$DGKO</span> | Decentralized marketplace for trading digital and physical products.</h2>
    
</div>

    <div className="elementor-background-overlay"></div> {/* Overlay div */}
    <div className="pre-sale-container">
    <div className="sale-countdown">
      {isCountdownFinished ? (
        <h2>LIVE NOW</h2>
      ) : (
        <>
          <h2>Countdown to $DGKO sale start</h2>
          <div className="countdown-timer">
            <p>{`${countdownTime.days}d ${countdownTime.hours}h ${countdownTime.minutes}m ${countdownTime.seconds}s`}</p>
          </div>
        </>
      )}
    </div>

      <div className="pre-sale-info">
        <div className="pre-sale-price">Price: 2.75 KLV</div>
        <div className="pre-sale-remaining">For Sale: {ktcBalance} DGKO</div>
      </div>
      <div className="pre-sale-balance">Balance: {balance} KLV</div>
      <div className="pre-sale-percentage-buttons">
  {['10', '25', '50', '75', '100'].map((percent) => (
    <button
      key={percent}
      className={`pre-btn-percentage ${!isConnected ? "disabled" : ""}`} // Add a "disabled" class or use the `disabled` attribute
      onClick={() => isConnected && handlePercentageClick(percent)}
      disabled={!isConnected} // Disable this button if the wallet is not connected
    >
      {percent}%
    </button>
  ))}
</div>

      <div className="pre-sale-inputs">
      <div className="input-group">
  <label htmlFor="pre-amount" className="pre-input-label">Amount:</label>
  <input
    id="pre-amount"
    type="number"
    className="pre-input-amount"
    placeholder="Min. 100 Max. 150k KLV"
    value={amount}
    onChange={handleAmountChange}
    onBlur={enforceConstraints}
    disabled={!isConnected} // Disable this input if the wallet is not connected
  />
</div>
        <div className="input-group">
          <label htmlFor="pre-receive" className="pre-input-label">Will Receive:</label>
          <input
            id="pre-receive"
            type="text"
            className="pre-input-receive"
            value={`${tokensToReceive} DGKO`}
            readOnly
          />
        </div>
      </div>
  
      <div className="pre-sale-actions">
                <button
                    className={`pre-btn-connect ${isConnected ? "disabled" : ""}`}
                    onClick={connectWallet}
                    disabled={isConnected}
                >
                    {isConnected ? shortenAddress(walletAddress) : 'Connect'}
                </button>
                <button
                className={`pre-btn-buy ${!isLoading && isConnected && isValidAmount && numericTokensToReceive <= numericKtcBalance && parseFloat(amount) <= parseFloat(balance) && isCountdownFinished ? 'buy-button-active' : ''}`}
                onClick={sendTransaction}
                disabled={isLoading || !isConnected || !isValidAmount || numericTokensToReceive > numericKtcBalance || parseFloat(amount) > parseFloat(balance) || !isCountdownFinished}
                  >
               {isLoading ? 'Processing...' : 'Buy'}
                </button>

            </div>
      {/* Always-present Transaction Status Message container */}
      <div className="presale-transaction-status-message" style={{ minHeight: '70px', maxWidth: '300px', visibility: transactionStatusMessage || transactionResultMessage ? 'visible' : 'hidden' }}>
  {(transactionResultMessage || transactionStatusMessage).split('\n').map((line, index, array) => (
    <React.Fragment key={index}>
      {line}{index < array.length - 1 && <br />}
    </React.Fragment>
  ))}
  {showInstallButton && (
    <button
      className="pre-btn-install"
      onClick={() => window.open('https://chromewebstore.google.com/detail/klever-wallet/ifclboecfhkjbpmhgehodcjpciihhmif', '_blank')}
    >
      INSTALL
    </button>
  )}
</div>

      </div>
    </div>
  );
}
  export default PreSaleDGKO;
  

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CollectionsPage from './pages/CollectionsPage';
import CollectionDetailPage from './pages/CollectionDetailPage';
import NftDetailsPage from './pages/NftDetailsPage';
import WalletPage from './pages/WalletPage';
import WalletPageTokens from './pages/WalletPageTokens';
import WalletHistoryPage from './pages/WalletHistoryPage';
import ScrollToTop from './components/ScrollToTop';
import { WalletProvider } from './components/WalletContext';
import WalletSalesPage from './pages/WalletSalesPage';
import WalletListingsPage from './pages/WalletListingsPage';
import WalletPurchasesPage from './pages/WalletPurchasesPage';
import AssetDetailsPage from './pages/AssetDetailsPage';
import TokenDetailsPage from './pages/TokenDetailsPage';
import Swap from './pages/swap';
import SwapKIDKLV from './pages/SwapKIDKLV';
import SwapKTCKLV from './pages/SwapKTCKLV';
import SwapKBLOCKLV from './pages/SwapKBLOCKLV';
import SwapKONGKLV from './pages/SwapKONGKLV';
import SwapMOTOKLV from './pages/SwapMOTOKLV';
import SwapVLXKLV from './pages/SwapVLXKLV';
import SwapGOATKLV from './pages/SwapGOATKLV';
import SwapKPEPEKLV from './pages/SwapKPEPEKLV';
import SwapTCTKLV from './pages/SwapTCTKLV';
import SwapSAVOKLV from './pages/SwapSAVOKLV';
import SwapKNTKLV from './pages/SwapKNTKLV';
import LaunchpadsOverview from './pages/LaunchpadsOverview';
import PrivateSaleDGKO from './pages/PrivateSaleDGKO';
import SwapKAKAFLUSH from './pages/SwapKakaFlush';
import ChatComponent from './components/ChatComponent';
import MintPage from './pages/MintPage';  // Import the MintPage component
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<LayoutWithHeaderAndFooter />} />
      </Routes>
    </Router>
  );
}

function LayoutWithHeaderAndFooter() {
  return (
    <WalletProvider>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/collections" element={<CollectionsPage />} />
          <Route path="/collections/:collectionId" element={<CollectionDetailPage />} />
          <Route path="/collections/:collectionId/:nftId" element={<NftDetailsPage />} />
          <Route path="/wallet/tokens/:assetId" element={<TokenDetailsPage />} />
          <Route path="/launchpad" element={<LaunchpadsOverview />} />
          <Route path="/swap" element={<Swap />} />

          {/* Add the MintPage route */}
          <Route path="/mint" element={<MintPage />} />

          <Route path="/swapKTCKLV" element={<SwapKTCKLV />} />
          <Route path="/swapKBLOCKLV" element={<SwapKBLOCKLV />} />
          <Route path="/swapKONGKLV" element={<SwapKONGKLV />} />
          <Route path="/swapMOTOKLV" element={<SwapMOTOKLV />} />
          <Route path="/swapVLXKLV" element={<SwapVLXKLV />} />
          <Route path="/swapGOATKLV" element={<SwapGOATKLV />} />
          <Route path="/swapKPEPEKLV" element={<SwapKPEPEKLV />} />
          <Route path="/swapKAKAFLUSH" element={<SwapKAKAFLUSH />} />
          <Route path="/swapTCTKLV" element={<SwapTCTKLV />} />
          <Route path="/swapSAVOKLV" element={<SwapSAVOKLV />} />
          <Route path="/swapKNTKLV" element={<SwapKNTKLV />} />
          <Route path="/wallet/:assetId" element={<AssetDetailsPage />} />
          <Route path="/wallet" element={<Outlet />}>
            <Route index element={<WalletPage />} />
            <Route path="tokens" element={<WalletPageTokens />} />
            <Route path="history" element={<WalletHistoryPage />}>
              <Route index element={<WalletHistoryPage />} />
              <Route path="sales" element={<WalletSalesPage />} />
              <Route path="listings" element={<WalletListingsPage />} />
              <Route path="purchases" element={<WalletPurchasesPage />} />
            </Route>
          </Route>
        </Routes>
        <Footer />
        <ChatComponent />
      </div>
    </WalletProvider>
  );
}

export default App;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../styles/Swap.css';
import klvLogo from '../assets/klever.png';
import ktcLogo from '../assets/KTC.png';
import kblocLogo from '../assets/kbloc.png';
import kongLogo from '../assets/KONG.png';
import motoLogo from '../assets/motokoin.png';
import vlxLogo from '../assets/vlxlogo.png';
import goatLogo from '../assets/goatLogo.png';
import kpepeLogo from '../assets/kpepeLogo.png';
import kakaLogo from '../assets/kakaLogo.png';
import flushLogo from '../assets/flushLogo.png';
import tctLogo from '../assets/tctLogo.png';
import savoLogo from '../assets/savoLogo.png';
import kntLogo from '../assets/kntLogo.png';

const tokenData = [
  { value: 'KONG', label: 'KONG', logo: kongLogo },
  { value: 'KTC', label: 'KTC', logo: ktcLogo },
  { value: 'KBLOC', label: 'KBLOC', logo: kblocLogo },
  { value: 'MOTO', label: 'MOTO', logo: motoLogo },
  { value: 'VLX', label: 'VLX', logo: vlxLogo },
  { value: 'GOAT', label: 'GOAT', logo: goatLogo },
  { value: 'KPEPE', label: 'KPEPE', logo: kpepeLogo },
  { value: 'TCT', label: 'TCT', logo: tctLogo },
  { value: 'SAVO', label: 'SAVO', logo: savoLogo },
  { value: 'KNT', label: 'KNT', logo: kntLogo },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#333',
    color: 'white',
    border: 'none',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#555' : '#333',
    color: state.data.value === 'KONG' ? '#f0b90b' : 'white',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    backgroundColor: '#333',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

const Swap = () => {
  const navigate = useNavigate();

  const handleChange = (option) => {
    navigate(`/Swap${option.value}KLV`);
  };

  const handleKakaFlushClick = () => {
    navigate('/SwapKAKAFLUSH');
  };

  const formatOptionLabel = ({ label, logo }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={logo} alt={label} style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '10px' }} />
      {label}
    </div>
  );

  return (
    <div className="token-swap-container">
      <div className="token-selector">
        <img src={klvLogo} alt="KLV Token" className="choose-token-logo" />
        <Select
          defaultValue={tokenData[0]}
          options={tokenData}
          styles={customStyles}
          onChange={handleChange}
          formatOptionLabel={formatOptionLabel}
          isSearchable={false}
        />
      </div>
      <p className="disclaimer-swap">All swap pairs have a fixed 20 KLV fee and 1% fee for liquidity. 14 KLV of the fixed fee is deposited to the FPR pool for $KONG stakers.</p>

      <div className="kaka-flush-selector clickable" onClick={handleKakaFlushClick}>
        <img src={kakaLogo} alt="KAKA Token" className="choose-token-logo" />
        <span className="token-label">KAKA</span>
        <span className="arrow">→</span>
        <img src={flushLogo} alt="FLUSH Token" className="choose-token-logo" />
        <span className="token-label">FLUSH</span>
      </div>
      <p className="flush-your-kaka">FLUSH your KAKA tokens!</p>
    </div>
  );
};

export default Swap;
